// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-e-ticket-js": () => import("./../../../src/pages/e-ticket.js" /* webpackChunkName: "component---src-pages-e-ticket-js" */),
  "component---src-pages-generate-link-js": () => import("./../../../src/pages/generate-link.js" /* webpackChunkName: "component---src-pages-generate-link-js" */),
  "component---src-pages-generate-qr-js": () => import("./../../../src/pages/generate-qr.js" /* webpackChunkName: "component---src-pages-generate-qr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-send-wishes-js": () => import("./../../../src/pages/send-wishes.js" /* webpackChunkName: "component---src-pages-send-wishes-js" */)
}

